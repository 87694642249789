import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <span>Work in progress</span>
        <span>Chandler Deming's personal website</span>
      </header>
    </div>
  );
}

export default App;
